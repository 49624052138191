var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "itemText",
                    label: "名称"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.model.itemText,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "itemText", $$v)
                      },
                      expression: "model.itemText"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "itemValue",
                    label: "数据值"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入数据值" },
                    model: {
                      value: _vm.model.itemValue,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "itemValue", $$v)
                      },
                      expression: "model.itemValue"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "描述"
                  }
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.model.description,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "description", $$v)
                      },
                      expression: "model.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "排序值"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.model.sortOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "sortOrder", $$v)
                      },
                      expression: "model.sortOrder"
                    }
                  }),
                  _vm._v("\n        值越小越靠前\n      ")
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "是否启用",
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      checkedChildren: "启用",
                      unCheckedChildren: "禁用"
                    },
                    on: { change: _vm.onChose },
                    model: {
                      value: _vm.visibleCheck,
                      callback: function($$v) {
                        _vm.visibleCheck = $$v
                      },
                      expression: "visibleCheck"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }