var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "content", staticClass: "card-list" },
    [
      _c("a-list", {
        attrs: {
          grid: { gutter: 24, lg: 3, md: 2, sm: 1, xs: 1 },
          dataSource: _vm.dataSource
        },
        scopedSlots: _vm._u([
          {
            key: "renderItem",
            fn: function(item, index) {
              return _c(
                "a-list-item",
                {},
                [
                  index === 0
                    ? [
                        _c(
                          "a-button",
                          { staticClass: "new-btn", attrs: { type: "dashed" } },
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _vm._v("\n          新增产品\n        ")
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "a-card",
                          { attrs: { hoverable: true } },
                          [
                            _c(
                              "a-card-meta",
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "margin-bottom": "3px" },
                                    attrs: { slot: "title" },
                                    slot: "title"
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _c("a-avatar", {
                                  staticClass: "card-avatar",
                                  attrs: {
                                    slot: "avatar",
                                    src: item.avatar,
                                    size: "large"
                                  },
                                  slot: "avatar"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "meta-content",
                                    attrs: { slot: "description" },
                                    slot: "description"
                                  },
                                  [_vm._v(_vm._s(item.content))]
                                )
                              ],
                              1
                            ),
                            _c(
                              "template",
                              {
                                staticClass: "ant-card-actions",
                                slot: "actions"
                              },
                              [
                                _c("a", [_vm._v("操作一")]),
                                _c("a", [_vm._v("操作二")])
                              ]
                            )
                          ],
                          2
                        )
                      ]
                ],
                2
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }