var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: "即时保存示例", bordered: false } },
    [
      _c("j-vxe-table", {
        attrs: {
          toolbar: "",
          toolbarConfig: _vm.toolbarConfig,
          "row-number": "",
          "row-selection": "",
          "keep-source": "",
          "async-remove": "",
          height: 340,
          loading: _vm.loading,
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          pagination: _vm.pagination
        },
        on: {
          save: _vm.handleTableSave,
          remove: _vm.handleTableRemove,
          "edit-closed": _vm.handleEditClosed,
          pageChange: _vm.handlePageChange,
          selectRowChange: _vm.handleSelectRowChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }