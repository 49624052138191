var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "result",
        {
          attrs: {
            "is-success": true,
            description: _vm.description,
            title: _vm.title
          }
        },
        [
          _c(
            "template",
            { slot: "action" },
            [
              _c("a-button", { attrs: { type: "primary" } }, [
                _vm._v("返回列表")
              ]),
              _c("a-button", { staticStyle: { "margin-left": "8px" } }, [
                _vm._v("查看项目")
              ]),
              _c("a-button", { staticStyle: { "margin-left": "8px" } }, [
                _vm._v("打印")
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "16px",
                    color: "rgba(0, 0, 0, 0.85)",
                    "font-weight": "500",
                    "margin-bottom": "20px"
                  }
                },
                [_vm._v("项目名称")]
              ),
              _c(
                "a-row",
                { staticStyle: { "margin-bottom": "16px" } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 } },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } },
                        [_vm._v("项目 ID：")]
                      ),
                      _vm._v("\n          20180724089\n        ")
                    ]
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 } },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } },
                        [_vm._v("负责人：")]
                      ),
                      _vm._v("\n          曲丽丽是谁？\n        ")
                    ]
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 12 } },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "rgba(0, 0, 0, 0.85)" } },
                        [_vm._v("生效时间：")]
                      ),
                      _vm._v("\n          2016-12-12 ~ 2017-12-12\n        ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-steps",
                {
                  attrs: {
                    current: 1,
                    direction:
                      (_vm.isMobile() && _vm.directionType.vertical) ||
                      _vm.directionType.horizontal,
                    progressDot: ""
                  }
                },
                [
                  _c(
                    "a-step",
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { "font-size": "14px" },
                          attrs: { slot: "title" },
                          slot: "title"
                        },
                        [_vm._v("创建项目")]
                      ),
                      _c("template", { slot: "description" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              fontSize: "12px",
                              color: "rgba(0, 0, 0, 0.45)",
                              position: "relative",
                              left: "42px"
                            },
                            attrs: { slot: "description" },
                            slot: "description"
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { margin: "8px 0 4px" } },
                              [
                                _vm._v(
                                  "\n                曲丽丽\n                "
                                ),
                                _c("a-icon", {
                                  staticStyle: { "margin-left": "8px" },
                                  attrs: { type: "dingding-o" }
                                })
                              ],
                              1
                            ),
                            _c("div", [_vm._v("2016-12-12 12:32")])
                          ]
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "a-step",
                    { attrs: { title: "部门初审" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { "font-size": "14px" },
                          attrs: { slot: "title" },
                          slot: "title"
                        },
                        [_vm._v("部门初审")]
                      ),
                      _c("template", { slot: "description" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              fontSize: "12px",
                              color: "rgba(0, 0, 0, 0.45)",
                              position: "relative",
                              left: "42px"
                            },
                            attrs: { slot: "description" },
                            slot: "description"
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { margin: "8px 0 4px" } },
                              [
                                _vm._v(
                                  "\n                周毛毛\n                "
                                ),
                                _c("a-icon", {
                                  staticStyle: {
                                    "margin-left": "8px",
                                    color: "#00A0E9"
                                  },
                                  attrs: { type: "dingding-o" }
                                })
                              ],
                              1
                            ),
                            _c("div", [
                              _c("a", { attrs: { href: "" } }, [
                                _vm._v("催一下")
                              ])
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  ),
                  _c("a-step", { attrs: { title: "财务复核" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px" },
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v("财务复核")]
                    )
                  ]),
                  _c("a-step", { attrs: { title: "完成" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px" },
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v("完成")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }