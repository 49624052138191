var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                model: _vm.model,
                rules: _vm.validatorRules
              }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "姓名",
                    required: "",
                    prop: "name",
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.model.name,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "name", $$v)
                      },
                      expression: "model.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: "关键词", prop: "keyWord", hasFeedback: "" }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入关键词" },
                    model: {
                      value: _vm.model.keyWord,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "keyWord", $$v)
                      },
                      expression: "model.keyWord"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "打卡时间",
                    prop: "punchTime",
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-date-picker", {
                    attrs: { showTime: "", valueFormat: "YYYY-MM-DD HH:mm:ss" },
                    model: {
                      value: _vm.model.punchTime,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "punchTime", $$v)
                      },
                      expression: "model.punchTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "性别", prop: "sex", hasFeedback: "" } },
                [
                  _c("j-dict-select-tag", {
                    attrs: {
                      type: "radio",
                      "trigger-change": true,
                      dictCode: "sex"
                    },
                    model: {
                      value: _vm.model.sex,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "sex", $$v)
                      },
                      expression: "model.sex"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "年龄", prop: "age", hasFeedback: "" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入年龄" },
                    model: {
                      value: _vm.model.age,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "age", $$v)
                      },
                      expression: "model.age"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "生日", prop: "age", hasFeedback: "" } },
                [
                  _c("a-date-picker", {
                    attrs: { valueFormat: "YYYY-MM-DD" },
                    model: {
                      value: _vm.model.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "birthday", $$v)
                      },
                      expression: "model.birthday"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "邮箱", prop: "email", hasFeedback: "" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.model.email,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "email", $$v)
                      },
                      expression: "model.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: "个人简介", prop: "content", hasFeedback: "" }
                },
                [
                  _c("a-input", {
                    attrs: { type: "textarea", placeholder: "请输入个人简介" },
                    model: {
                      value: _vm.model.content,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "content", $$v)
                      },
                      expression: "model.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }